import React from "react";
import Layout from "../../components/layout";
import sptm1 from "../../images/sptm1.jpeg";
import sptm2 from "../../images/sptm2.jpeg";
import SEO from "../../components/seo";

function SPTM() {
  return (
    <Layout>
      <SEO
        keywords={[`Golden`, `Sondagens`, `SPT`, `Construção`]}
        title="Sondagem SPTM"
      />

      <section
        style={{
          height: "300px",
          backgroundImage: `linear-gradient(to right, hsla(0, 0%, 0%, 1), hsla(0, 0%, 0%, 0.3)), url(${sptm2})`,
          backgroundColor: "gray",
          backgroundSize: "cover",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
          backgroundAttachment: "fixed",
        }}
      >
        <h1 className="font-bold text-white m-2 md:ml-8 md:mt-24 uppercase base-title-golden">
          Sondagem à Percussão (SPT)
        </h1>
      </section>

      <div className="grid grid-cols-1 md:grid-cols-2 gap-8 m-2 xl:m-12">
        <div>
          <h3>
          A Sondagem SPT Manual é uma técnica crucial na investigação geotécnica do solo. 
          Utilizando um equipamento específico, realizamos a coleta de amostras para análise em diferentes profundidades. 
          Este método fornece informações precisas sobre a resistência do solo, auxiliando na elaboração de projetos de fundação e estrutura. 
          Contamos com uma equipe especializada para realizar essa sondagem de forma eficiente e confiável.
          </h3>
        </div>
        <img src={sptm1} />
      </div>
    </Layout>
  );
}

export default SPTM;
